import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import DropDown from "../components/Dropdown";

// eslint-disable-next-line
export const NationalResourcesTemplate = ({
  title,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <p>
                Casting all your anxiety on Him because it matters to Him
                concerning you.
              </p>
              <p>1 Peter 5:7</p>
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <PageContent className="content" content={content} />
              <h2 className="mid-title title">General Information</h2>
              <DropDown
                question="MentalHealth.gov"
                link="https://www.mentalhealth.gov"
              >
                <>
                  <p>
                    Comprehensive information on mental health disorders
                    provided by the United States government including Centers
                    for Disease Control and Prevention, National Institute of
                    Mental Health, and the Substance Abuse and Mental Health
                    Services Administration
                  </p>
                </>
              </DropDown>
              <DropDown
                question="National Alliance on Mental Illness (NAMI)"
                link="https://www.nami.org/Home"
                contact="NAMI Helpline: 1-800-950-NAMI (6264)"
              >
                <>
                  <p>
                    Comprehensive information on mental health disorders and
                    resources for advocacy, education, support, and public
                    awareness for individuals and families affected by mental
                    illness
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Mental Health America (MHA)"
                link="https://www.mhanational.org"
              >
                <>
                  <p>
                    Comprehensive information on mental health disorders and
                    resources for advocacy, education, research, and services
                    for individuals and families affected by mental illness
                  </p>
                </>
              </DropDown>
              <DropDown
                question="National Institute of Mental Health (NIMH)"
                link="https://www.nimh.nih.gov/index.shtml"
              >
                <>
                  <p>
                    Comprehensive information and research on mental health
                    disorders
                  </p>
                </>
              </DropDown>
              <DropDown
                question="American Psychological Association (APA)"
                link="https://www.apa.org/topics"
              >
                <>
                  <p>
                    Comprehensive information regarding psychology, mental
                    health disorders, and mental health treatment
                  </p>
                </>
              </DropDown>
              <DropDown
                question="American Psychiatric Association (APA)"
                link="https://www.psychiatry.org/patients-families"
              >
                <>
                  <p>
                    Comprehensive information regarding psychiatry, mental
                    health disorders, and mental health treatment
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Mental Health First Aid"
                link="https://www.mentalhealthfirstaid.org"
              >
                <>
                  <p>
                    Training courses to identify, understand, and respond to
                    signs of mental illnesses and substance use disorders
                  </p>
                </>
              </DropDown>

              <h2 className="mid-title title">Find Treatment</h2>

              <DropDown
                question="Psychology Today"
                link="https://www.psychologytoday.com/us"
              >
                <>
                  <p>
                    Directory of mental health professionals and treatment
                    centers for locating treatment providers
                  </p>
                </>
              </DropDown>

              <DropDown
                question="Find a Psychiatrist"
                link="http://finder.psychiatry.org"
              >
                <>
                  <p>
                    Directory of psychiatrists provided by the American
                    Psychiatric Association for locating treatment providers
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Psychologist Locator"
                link="https://locator.apa.org"
              >
                <>
                  <p>
                    Directory of psychologists provided by the American
                    Psychological Association for locating treatment providers
                  </p>
                </>
              </DropDown>
              <DropDown question="Low Cost Help" link="http://lowcosthelp.com">
                <>
                  <p>
                    Directory of sliding scale counseling services for
                    individuals with financial limitations
                  </p>
                </>
              </DropDown>

              <h2 className="mid-title title">
                Resources for Mental Health Disorders
              </h2>

              <DropDown
                question="Anxiety and Depression Association of America (ADAA)"
                link="https://adaa.org"
              >
                <>
                  <p>
                    Information, resources, and support regarding prevention and
                    treatment of depression, anxiety, and other mental health
                    disorders
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Anxiety Canada"
                link="https://www.anxietycanada.com"
              >
                <>
                  <p>
                    Information, resources, and support for individuals and
                    families affected by anxiety disorders
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Depression and Bipolar Support Alliance"
                link="https://www.dbsalliance.org"
              >
                <>
                  <p>
                    Information, resources, and support for individuals and
                    families affected by depression and bipolar disorders
                  </p>
                </>
              </DropDown>
              <DropDown
                question="National Eating Disorders Association (NEDA)"
                link="https://www.nationaleatingdisorders.org"
                contact="NEDA Helpline: 1-800-931-2237"
              >
                <>
                  <p>
                    Information, resources, and support for individuals and
                    families affected by eating disorders
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Schizophrenia and Related Disorders Alliance of America (SARDAA)"
                link="https://sardaa.org"
              >
                <>
                  <p>
                    Information, resources, and support for individuals and
                    families affected by schizophrenia and related disorders
                  </p>
                </>
              </DropDown>

              <h2 className="mid-title title">
                Resources for Abuse and Trauma
              </h2>

              <DropDown
                question="National Domestic Violence Hotline"
                link="https://www.thehotline.org"
                contact="National Domestic Violence Hotline: 1-800-799-7233"
              >
                <>
                  <p>
                    Information, resources, and support for individuals and
                    families affected by domestic violence
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Rape, Abuse, and Incest National Network (RAINN)"
                link="https://www.rainn.org"
                contact="National Sexual Assault Telephone Hotline: 1-800-656-HOPE (4673)"
              >
                <>
                  <p>
                    Information, resources, and support for individuals and
                    families affected by sexual violence
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Childhelp"
                link="https://www.childhelp.org"
                contact="National Child Abuse Hotline: 1-800-4-A-CHILD or 1-800-422-4453"
              >
                <>
                  <p>
                    Information, resources, and support for children and
                    families affected by child abuse
                  </p>
                </>
              </DropDown>
              <DropDown
                question="National Center for PTSD"
                link="https://www.ptsd.va.gov/"
              >
                <>
                  <p>
                    Information, resources, and support for individuals and
                    families affected by posttraumatic stress disorder
                  </p>
                </>
              </DropDown>
              <DropDown
                question="PTSD Foundation of America"
                link="https://ptsdusa.org"
              >
                <>
                  <p>
                    Information, resources, and support for veterans and
                    first-responders affected by posttraumatic stress disorder
                  </p>
                </>
              </DropDown>
              <DropDown
                question="The National Child Traumatic Stress Network (NCTSN)"
                link="https://www.nctsn.org"
              >
                <>
                  <p>
                    Information, resources, and support for children and
                    families affected by posttraumatic stress disorder
                  </p>
                </>
              </DropDown>
              <DropDown
                question="SAMHSA Disaster Distress Helpline"
                link="https://www.samhsa.gov/find-help/disaster-distress-helpline"
                contact="1-800-985-5990 or text TalkWithUs to 66746"
              >
                <>
                  <p>
                    A helpline providing counseling and support to people
                    experiencing emotional distress related to natural or human
                    caused disasters
                  </p>
                </>
              </DropDown>

              <h2 className="mid-title title">
                Resources for Addiction and Substance Use Disorders
              </h2>

              <DropDown
                question="Substance Abuse and Mental Health Services Administration (SAMHSA)"
                link="https://www.samhsa.gov/find-treatment"
                contact="SAMHSA’s National Helpline: 1-800-662-HELP (4357)"
              >
                <>
                  <p>
                    Various resources for locating treatment for substance abuse
                    and mental health disorders
                  </p>
                </>
              </DropDown>
              <h2 className="mid-title title">
                Resources for Suicide and Self Injury
              </h2>

              <DropDown
                question="National Suicide Prevention Lifeline"
                link="https://suicidepreventionlifeline.org"
                contact="988"
              >
                <>
                  <p>
                    A helpline providing free and confidential support for
                    people in suicidal crisis
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Crisis Text Line"
                link="https://www.crisistextline.org"
                contact="Text HOME to 741741"
              >
                <>
                  <p>
                    A helpline providing free and confidential support for
                    people in emotional crisis
                  </p>
                </>
              </DropDown>
              <DropDown
                question="American Foundation for Suicide Prevention (ASFP)"
                link="https://afsp.org"
              >
                <>
                  <p>
                    Information, resources, and support for individuals
                    struggling with suicidal thoughts
                  </p>
                </>
              </DropDown>
              <DropDown question="My3 Application" link="https://my3app.org">
                <>
                  <p>
                    A mobile application providing support and safety planning
                    for individuals struggling with suicidal thoughts
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Adolescent Self Injury Foundation (ASIF)"
                link="https://www.adolescentselfinjuryfoundation.com"
              >
                <>
                  <p>
                    Information, resources, and support for individuals
                    struggling with self-injury
                  </p>
                </>
              </DropDown>


              <h2 className="mid-title title">Resources for Married Couples</h2>

              <DropDown
                question="The Gottman Institute"
                link="http://www.gottman.com"
              >
                <>
                  <p>
                    Information, resources, and support for relationship
                    difficulties
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Dr. Sue Johnson"
                link="http://www.drsuejohnson.com"
              >
                <>
                  <p>
                    Information, resources, and support for relationship
                    difficulties
                  </p>
                </>
              </DropDown>

              <h2 className="mid-title title">Resources for Parents</h2>

              <DropDown
                question="Kelty Mental Health Resource Centre"
                link="https://keltymentalhealth.ca/parents-caregivers"
              >
                <>
                  <p>
                    Information, resources, and support for parents of children
                    with mental health disorders
                  </p>
                </>
              </DropDown>
              <DropDown
                question="CHOC Mental Health Toolkit"
                link="http://choc.org/mental-health/mental-health-toolkit/"
              >
                <>
                  <p>
                    Information, resources, and support for parents of children
                    with mental health disorders
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Teen Mental Health"
                link="http://teenmentalhealth.org/learn"
              >
                <>
                  <p>
                    Information, resources, and support for parents of teenagers
                    with mental health disorders
                  </p>
                </>
              </DropDown>
              <DropDown
                question="TeensHealth"
                link="https://teenshealth.org/en/teens/#catfeelings"
              >
                <>
                  <p>
                    Information, resources, and support regarding mental health
                    for parents, children, teenagers, and educators
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Ken Rigby Bullying"
                link="https://www.kenrigby.net"
              >
                <>
                  <p>
                    Information, resources, and support for parents and teachers regarding bullying
                  </p>
                </>
              </DropDown>

              <h2 className="mid-title title">Resources for Special Education</h2>

              <DropDown
                question="Understood"
                link="https://www.understood.org"
              >
                <>
                  <p>
                    Information, resources, and support for children with developmental disabilities
                  </p>
                </>
              </DropDown>

              <DropDown
                question="Wrightslaw Yellow Pages for Kids"
                link="https://www.yellowpagesforkids.com/"
              >
                <>
                  <p>
                    Directory for locating resources for children with
                    developmental disabilities
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Alliance for Children’s Rights"
                link="https://www.allianceforchildrensrights.org"
              >
                <>
                  <p>
                    Information, resources, and support for children requiring special education
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Autism Speaks"
                link="https://www.autismspeaks.org/"
              >
                <>
                  <p>
                    Information, resources, and support for individuals and
                    families affected by autism spectrum disorder
                  </p>
                </>
              </DropDown>

              <h2 className="mid-title title">Resources for Social Media</h2>

              <DropDown
                question="Facebook Suicide Prevention"
                link="https://www.facebook.com/help/594991777257121"
              >
                <>
                  <p>Resources for suicidal messages on Facebook</p>
                </>
              </DropDown>
              <DropDown
                question="Instagram Safety Center"
                link="https://help.instagram.com"
              >
                <>
                  <p>Resources for suicidal messages on Instagram</p>
                  <p>
                    [use search term "suicide", "self-injury" or "suicide
                    prevention"]
                  </p>
                </>
              </DropDown>
              <DropDown
                question="Snapchat Support"
                link="https://support.snapchat.com"
              >
                <>
                  <p>Resources for suicidal messages on Snapchat</p>
                  <p>[use search term "suicide" or "suicide prevention"]</p>
                </>
              </DropDown>
              <DropDown
                question="Twitter's Best Practices in Dealing with Self-Harm and Suicide"
                link="https://support.twitter.com"
              >
                <>
                  <p>Resources for suicidal messages on Twitter</p>
                  <p>
                    [use search term "suicide", "self-injury" or "suicide
                    prevention"]
                  </p>
                </>
              </DropDown>
              <DropDown
                question="YouTube's Safety Center"
                link="https://support.google.com/youtube"
              >
                <>
                  <p>Resources for suicidal messages on YouTube</p>
                  <p>[use search term "suicide and self-injury"]</p>
                </>
              </DropDown>
              <DropDown
                question="Tumblr Counseling and Prevention Resources"
                link="https://tumblr.zendesk.com"
              >
                <>
                  <p>Resources for suicidal messages on Tumblr</p>
                  <p>[use search term "counseling" or "prevention"]</p>
                </>
              </DropDown>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

NationalResourcesTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const NationalResourcesPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <NationalResourcesTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

NationalResourcesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NationalResourcesPage;

export const NationalResourcesQuery = graphql`
  query NationalResourcesQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
